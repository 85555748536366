import React from 'react';
import logo from './logo.svg';
import './App.css';

class App extends React.Component {

  constructor(){
    super();
    this.popTab = this.popTab.bind(this);
  }



  popTab(){
    window.location.href="https://docs.google.com/forms/d/1f2z7vVWPQOqxgw6pbrYNUfUCiqALCGRbqhRmWwhCNhM/edit?usp=sharing_eip&ts=5ecb1419";
  }

  render(){
    return (
      <div className="App">
        <div id='comingSoon'>
        
          
          <img src='https://comanchestore.com/images/manchesca.png' id='comingSoonLogo' />
          <h1 id='comingSoonHeading'>ComancheStore Coming Soon</h1>
          <button id='testBtn' onClick={this.popTab}>Help Us Test</button>

        </div>
      </div>
    )
  }
}

export default App;
